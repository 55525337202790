* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

html,
body,
#root {
  height: 100%;
  background-color: #000;
}

a {
  color: white;
  text-decoration: none;
}

.color-primary {
  background-color: #283241;
}

.center-vertical {
  justify-content: center;
  justify-items: center;
  display: flex;
}

body {
  font: 14px "Roboto", sans-serif;
  background-color: #1a1919;
  -webkit-font-smoothing: antialiased !important;
}

.desktop-home {
  display: block;
}

.mobile-home {
  display: none;
}

@media (max-width: 768px) {
  .desktop-home {
    display: none;
  }
  .mobile-home {
    display: block;
  }
}

#articleMenu {
  scroll-behavior: smooth;
}

.header-desk {
  background: #000;
  color: white;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 25px;
  margin-top: 15px;
}

.menu-hidden .header-desk {
  margin-bottom: 10px;
}

.header-desk div.header-title {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 25px;
  height: 50px;
  overflow: hidden;
}

.header-desk h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
}

.header-desk h4 span {
  font-weight: normal;
}

.root-tab > header.header-fixed {
  position: sticky;
  top: 0;
  min-height: 2px;
  background-color: #232933;
}

@media (max-width: 768px) {
  .header-desk {
    display: none;
  }
}

h1.title {
  text-align: center;
  color: white;
  line-height: 100px;
  margin: 0;
}

article {
  // padding: 10px;
  // margin: 10px;
  background: #000;
}

article video {
  width: 100%;
}

section {
  display: flex;
  flex-direction: row;
  height: 100%;
}

@media (max-width: 768px) {
  section {
    display: contents;
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 0 !important;
  }
}

.MuiTab-root {
  text-transform: none;
}

article:nth-of-type(1) {
  width: 75%;
  display: flex;
  flex-flow: column;
  transition: all 0.5s;
}

.options-itens {
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex: 1 50px;
  width: 75%;
}

.video-options {
  background: #283241;
  height: 80px;
  color: white;
  padding: 20px;
  flex: 1;
  position: fixed;
  bottom: 0px;
  width: 75%;
  transition: all 0.5s;
  border-right: 1px solid #122934;
}

.root-fixed {
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  right: 0px;
  width: 25%;
  top: 0px;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s;
  height: 100%;
}

@media (max-width: 1366px) {
  article:nth-of-type(1),
  .options-itens,
  .video-options {
    width: 70%;
  }
  .root-fixed {
    width: 30%;
  }
}

article.menu-hidden {
  width: 100%;
}

.player,
.player-pdf {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.player-pdf {
  width: 90%;
  flex-direction: column;

  .download-pdf {
    width: 300px;
    margin-top: 20px;
    align-self: flex-end;
  }
}

.player-pdf iframe {
  min-height: 500px;
}

.player .player-prev,
.player .player-next {
  width: 10%;
  justify-items: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
}

.player-prev button,
.player-next button {
  font-weight: bold;
  color: white;
  font-size: 14px;
  align-items: center;
  display: flex;
  @media(max-width: 769px) {
    font-size: 12px;
  }
}

.btn-disabled button {
  color: #9B9B9B;
  cursor: not-allowed;

}

.btn-disabled-wait button {
  color: #9B9B9B;
  cursor: wait;

}

.icon-prev {
  margin-right: 10px;
}

.icon-next {
  margin-left: 10px;
}

article iframe {
  border: none;
  height: 100%;
  min-height: 1px;
  width: 100%;
  transition: all 0.5s;
}

@media (max-width: 1366px) {
  article iframe {
    min-height: 428px;
  }
}

@media (max-width: 769px) {
  .player-pdf {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 350px;

    .btn-default-yellow {
      width: 80%;
    }
  }
  .player-pdf iframe {
    min-height: 200px;
    height: 300px !important;
  }
  article:nth-of-type(1) {
    width: 100%;
    min-height: 420px;
  }
  article iframe {
    min-height: 230px;
  }
  .player {
    width: 95%;
    padding-top: 0px;

    &.videojs {
      width: 100%;
      padding-bottom: 0px;
    }
  }
  .player .player-prev,
  .player .player-next {
    display: none;
  }
  .video-options {
    border-right: none;
  }
}

// next e prev

.show-next-prev-desktop {
  display: inline-block;
}

.show-next-prev-mobile {
  display: none;
}

.container-next-prev {
  background: #28323E;
  color: white;
  width: 250px;
}

.container-item-next-prev {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 0;
  @media(max-width: 769px) {
    min-height: 35px;
  }
}

@media (max-width: 850px) {
  .container-next-prev {
    width: 100%;
  }
  .container-item-next-prev {
    padding: 0 28px;
  }
}

@media (max-width: 768px) {
  .container-next-prev {
    background: #232933;
    width: 100%;
  }
  .show-next-prev-mobile {
    display: inline-block;
  }
  .show-next-prev-desktop {
    display: none;
  }
}


// fim next e prev

// video opções
.div-options-audio,
.div-options-pdf,
.div-options-save-video {
  text-align: center;
}

.div-options-audio img {
  width: 26px;
}

.div-options-pdf img {
  width: 20px;
}

.div-options-save-video img {
  width: 26px;
}

.div-options-pdf p,
.div-options-audio div,
.div-options-save-video p {
  font-size: 14px;
  font-weight: 400;
}

.div-options-salve-video {
  cursor: pointer;
}

.div-options-salve-video p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.options-likes {
  display: flex;
  justify-content: space-around;
}

.options-likes img {
  width: 25px;
}

.options-likes .thumbup {
  color: #0072ce;
}

.options-likes .thumbdown {
  color: #0072ce;
}

@media (max-width: 768px) {
  .div-options-salve-video p {
    font-size: 12px;
  }
  .div-options-audio img,
  .div-options-pdf img,
  .div-options-save-video img,
  .options-likes img {
    width: auto;
  }
  .div-options-pdf p,
  .div-options-audio div,
  .div-options-save-video p {
    font-size: 12px;
  }
  .options-likes {
    width: 100%;
  }
}

@media (max-width: 340px) {
  .div-options-pdf p,
  .div-options-audio div,
  .div-options-save-video p {
    font-size: 10px;
  }
}

.div-title h4 {
  padding-bottom: 10px;
  @media(max-width: 769px) {
    font-size: 12px;
  }
}

.div-title p {
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  @media(max-width: 769px) {
    font-size: 12px;
  }
}

.subdiv-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  @media(max-width: 769px) {
    font-size: 12px;
  }
}

.subdiv-title img {
  width: 20px;
}

.subdiv-title div {
  width: 90%;
}

.video-options.close {
  width: 100%;
}

@media (max-width: 768px) {
  .video-options {
    padding: 10px;
    position: initial;
    width: 100%;
  }
}

.video-options-flex {
  flex: 1 50px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1120px) {
    width: 100%;
  }
  @media(max-width: 769px) {
    > .options-likes {
      .MuiSvgIcon-root {
        height: 20px;
      }
    }
  }
}

#alterarPlayer {
  @media(max-width: 769px) {
    p {
      font-size: 12px;
    }
  }
}


.options-likes {
  display: flex;
  justify-content: flex-start;
}

.options-likes > * {
  margin-left: 30px;
}

.options-itens {
  width: 55%;
  display: flex;
  justify-content: flex-end;
}

.options-itens > div {
  margin-left: 30px;
}

// fim video opções

// modulos

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 25%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.header-desk div.div-icon-right {
  background: #232933;
  width: 50px;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-desk div.div-icon-right.close {
  width: 50px;
}

.div-icon-right img {
  margin: 0 auto;
}

.select-padrao ul li span.itemSelected:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 61px;
  background-color: #fcd64b;
}

.MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}

.divModule-graphic,
.root-tab {
  .modulos {
    .summary-module {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0px;
      cursor: pointer;
      border-left: 4px solid transparent;

      &.active {
        border-left: 4px solid #fcd64b;
      }

      .VictoryContainer {
        width: 70px !important;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}

.custom-accordion .MuiAccordionSummary-root.Mui-disabled {
  opacity: 0.8;

  .MuiAccordionSummary-expandIcon {
    display: none;
  }
}

.divModule-opcoes,
.divModule-playlist {
  width: 100%;
}

.divModule-opcoes p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 10px 15px 5px;
}

.opened {
  .MuiSvgIcon-root {
    transform: rotate(180deg);
  }
}

.VictoryContainer svg text {
  display: none;
}

.VictoryContainer + text {
  position: absolute;
  left: 16px;
  text-align: center;
  width: 33px;
}

.VictoryContainer + text > tspan {
  font-size: 12px !important;
}

@media(max-width: 768px) {
  .VictoryContainer + text {
    left: 9px;
    padding-bottom: 3px;
  }
  .VictoryContainer + text > tspan {
    font-size: 12px !important;
  }
}

@media (max-width: 1366px) {

  .divModule-opcoes {
    padding-left: 10px;
  }

  .VictoryContainer + text {
    left: 13px;
  }
  .divModule-graphic,
  .root-tab .MuiAccordionSummary-content .VictoryContainer {
    width: 60px !important;
  }
  .divModule-opcoes p {
    font-size: 14px;
    padding: 10px 0px 15px 0px;
  }
}

.divModule-playlist p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.text-overflow {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 255px;
  min-width: initial;
}

.module-options-flex {
  display: flex;
  justify-content: space-between;
}

.module-options-flex p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  padding: 0;
}

.div-material-class {
  display: flex;
  text-align: center;
}

.div-material-class .download-pdf-module {
  width: 115px;
  background: #FDE796;
  text-align: center;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.div-material-class .download-pdf-module a {
  color: #666666;
  line-height: 10px;
  font-size: 12px;
  font-weight: 600;
}

.div-material-class .download-pdf-module img {
  width: 16px;
}

.div-material-class p {
  padding-right: 15px;
}

.divModule-aulas {
  width: 80%;
}

.divModule-aulas p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: auto;
}

.divModule-youtube-pdf {
  display: flex;
  justify-content: left;
  width: 80%;
  margin: 20px 10px;
}

.divModule-youtube-pdf img {
  padding: 0 5px;
}

.divModule-youtube-pdf div {
  justify-content: left;
  justify-items: center;
  display: flex;
}

.divModule-conteudo {
  display: flex;
  justify-content: left;
  width: 80%;
  margin: 20px 0;
}

.divModule-conteudo img {
  padding: 0 20px;
}

.divModule-conteudo label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.custom-accordion .MuiCheckbox-colorSecondary.Mui-checked {
  color: #fff;
}

.MuiIconButton-label {
  color: white;
}

.MuiListItemAvatar-root {
  width: 100%;
}

.accordion .MuiSnackbarContent-message {
  padding: 8px 15px;
}

.div-pai-doubleIcon-list {
  display: flex;
  margin-bottom: 0px;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px 5px 3px;
  min-height: 60px;
}

.MuiDialog-container .MuiPaper-root {
  background-color: #232933;
  color: #ffffff;
  min-width: 280px;
  min-height: 150px;
  padding: 10px;
}

.MuiDialog-container .MuiDialogTitle-root {
  padding-left: 15px;
}

.MuiDialog-container .MuiDialogTitle-root > h2 {
  font-size: 18px;
}

.MuiDialogActions-root .btn-cancel-dialog {
  background-color: #9b9b9b;
  color: #ffffff;
  text-transform: none;
}

.MuiDialogActions-root .btn-cancel-dialog:hover {
  background-color: #7d7d7d;
  color: #ffffff;
}

.MuiDialogActions-root .btn-confirm-dialog {
  background-color: #fcd64b;
  color: #5a5e5c;
  text-transform: none;
  text-transform: none;
}

.MuiDialogActions-root .btn-confirm-dialog:hover {
  background-color: #edc121;
  color: #5a5e5c;
}

.div-pai-doubleIcon-list button,
.module-playlist-flex button {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  padding-right: 15px;
}

.label-icons {
  margin: 0px 1px;
}

.div-doubleIcon-list {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 55px;
  margin-left: 5px;
}

.MuiPaper-elevation8 {
  background-color: #5c6a7e !important;
}

@media (max-width: 1366px) {
  .div-doubleIcon-list > svg {
    width: 35%;
  }
  .div-doubleIcon-list > svg:only-child {
    width: 100%;
  }
  .module-options-flex p {
    font-size: 11px;
  }
}

.div-text-list {
  flex: 7;
}

.div-text-list-padding {
  padding-left: 10px;
}

.div-info{
  display: flex;
  gap: 4px;
  align-items: center;
}

.div-text-list p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
}

.div-text-list .title-conteudo {
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
}

.Mui-selected .div-text-list .title-conteudo {
  color: #24ce7d;
}

.tree_line {
  width: 34px;
  height: 0px;
  left: 30px;
  top: 70px;
  border: 1px solid #283241;
  transform: rotate(90deg);
}

.tree_line_vertical {
  width: 25px;
  padding-right: 24px;
  height: 0px;
  margin-left: 16px;
  border: 1px solid #283241;
  margin-top: 15px;
}

.div-parts {
  display: flex;
}

.divOne-parts {
  margin-left: 13px;
}

.divTwo-parts {
  padding-left: 10px;
  margin-top: 10px;
}

.divTwo-parts button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #fff;
}

.divWarn p {
  color: #EB5757;
  padding-left: 30px;
}

.divTwo-parts.selected button {
  color: #24ce7d;
}

.divTwo-parts span {
  font-size: 14px;
}

.divTwo-parts span.min {
  font-size: 12px;
}

.module-playlist-flex {
  display: flex;
}

.module-playlist-flex p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  padding-right: 20px;
}

.divModule-playlist-title {
  display: flex;
  width: 100%;
  padding-left: 20px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.divModule-playlist-title p {
  padding-right: 20px;
  padding-top: 3px;
}

.divModule-playlist-lesson {
  width: 100%;
  padding-left: 20px;
}

.input-new-playlist {
  width: 100%;
  height: 50px;
  margin: 20px 0 15px 0;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  border: none;
}

.modalpai-playlist-edit label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  padding: 10px 0 20px 0;
}

.modalpai-playlist-edit p {
  cursor: pointer;
}

.btn-newplaylist-close {
  display: flex;
  justify-content: flex-end;
}

.btn-newplaylist-close button {
  border: none;
  background-color: #9b9b9b;
  color: #ffffff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  cursor: pointer;
}

.title-playlist {
  display: flex;
  margin-bottom: 0px;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px 5px 3px;
  min-height: 60px;
}

.title-playlist p {
  white-space: nowrap;
  overflow: hidden;
  width: 95%;
  text-overflow: ellipsis;
  text-align: left;
}

.title-playlist p.center {
  text-align: center;
}

.btn-save-playlist {
  width: 100%;
  background: #fcd64b;
  color: #5a5e5c;
  text-align: center;
  font-weight: bold;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 auto 30px auto;
  border: none;
  line-height: 16px;
}

.btn-save-playlist:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-default-yellow {
  width: 100%;
  margin: 0 auto;
  background: #fcd64b;
  color: #5a5e5c;
  text-align: center;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.MuiSnackbarContent-root {
  background-color: green !important;
}

.skeletonConteudo {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-around;
}

// fim modulos

.btn-go-back,
.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  flex: 0;
}

.btn-icon-padding {
  padding: 0 10px;
}

.div-btn-mobile {
  margin: 7px 0px 0px 5px;
}

.btn-go-back {
  span {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
  }

  @media(max-width: 769px) {
    img {
      height: 18px;
    }
  }
}

.btn-icon {
  color: #fff;
}

.btn-menu {
  margin-top: -32px;
}

#root article .center-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: initial;
  padding: 30px 0px;
}

#root article .center-loading div.MuiCircularProgress-root {
  flex: initial;
}

#root .MuiList-padding {
  padding-top: 1px;
  padding-bottom: 1px;
}

p.divAula-opcoes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
  display: block;
}

@media (min-width: 769px) and (max-width: 1024px) {
  p.divAula-opcoes {
    max-width: 120px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  p.divAula-opcoes {
    max-width: 180px;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  p.divAula-opcoes {
    max-width: 220px;
  }
}

@media (max-width: 400px) {
  p.divAula-opcoes {
    max-width: 240px;
  }
}

@media (max-width: 350px) {
  p.divAula-opcoes {
    max-width: 200px;
  }
}

.divAula-graphic {
  width: 70px;
}

.accordion {
  height: 100%;
  flex: 1;
}

.custom-accordion {
  flex-direction: column;
}

.accordion .MuiPaper-root,
.accordion .MuiPaper-root .MuiAccordionDetails-root,
.custom-accordion .MuiButtonBase-root {
  padding: 0px;
}

.accordion .MuiIconButton-root {
  padding: 0px;
  color: #fff;
}


.MuiIconButton-label {
  padding-left: 10px;
  padding-right: 10px;
}

.custom-accordion .summary-aula {
  cursor: pointer;
  width: 100%;
  background-color: #3e4d63;
  display: flex;
  align-items: center;

  .VictoryContainer {
    width: 70px !important;
  }

  .summary-aula-name {
    flex: 1;
  }

  .divWarn {
    display: flex;
    gap: 5px;
  }

  .divWarn img {
    width: 15px;
  }

  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: default;
  }
}

.accordion-aulas {
  .custom-accordion-aulas {
    transition: all 1.5s ease-in-out;
    display: none;
  }

  .custom-accordion-aulas.active {
    display: flex;
  }
}

.custom-accordion .summary-aula,
.custom-accordion .summary-aula.Mui-expanded {
  min-height: 50px;
}

.accordion .MuiAccordionSummary-content,
.accordion .MuiAccordionSummary-content.Mui-expanded,
.custom-accordion .MuiAccordion-root.Mui-expanded,
.custom-accordion .MuiAccordion-root,
.accordion .MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

.accordion .MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}

.MuiAccordionSummary-content {
  align-items: center;
}

.custom-accordion .MuiAccordionSummary-content {
  margin: 0px;
  max-height: 50px;
}

.accordion > .MuiPaper-root {
  min-height: 60px;
}

.root-fixed.hide {
  transform: translate3d(100%, 0, 0);
  transition: all 0.5s;
}

.root-tab {
  text-transform: none;
  width: 100%;
  background-color: #283241;
  color: #fff;
  position: initial;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.root-tab > div {
  height: 100%;
}

.background-tab {
  background-color: #232933;
  @media(max-width: 769px) {
    * {
      font-size: 12px
    }
    .MuiTab-root, .MuiTabs-root {
      min-height: 40px
    }

  }
}

.tab-item {
  width: 100%;
  height: 100%;
  flex: 1;
}

/* width */

.quill {
  color: black;
  font-size: 16px;
  padding-top: 10px;
}

article .ql-toolbar {
  margin-left: 15px;
  margin-right: 15px;
  background: white;
  border: none;
}

article .ql-container .ql-snow {
  border: none;
  height: auto;
}

article .ql-container.ql-snow {
  height: 87%;
}

article .ql-editor {
  height: 100%;
  padding-top: 10px;
  font-size: 16px;
}

article .ql-toolbar.ql-snow {
  margin: 0px
}

article .quill {
  padding-top: 0px
}

@media(max-width: 768px) {
  article .ql-toolbar.ql-snow {
    margin: 0px;
  }
}

article .ql-editor * {
  font-family: Helvetica, Arial, sans-serif
}

article .ql-editor strong {
  font-weight: 600;
}

article .ql-editor ol, article .ql-editor ul {
  padding-left: 0.5em;
}

.btn-save-annotations {
  bottom: 0;
  right: 0;
  height: 35px;
  width: 103px;
  background: #24ce7d;
  margin: 10px;
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.btn-save-annotations:hover {
  background: #1dad69;
}

.btn-save-not-allowed {
  cursor: not-allowed;
  background: #979797;
}

.btn-save-not-allowed:hover {
  cursor: not-allowed;
  background: #979797;
}

.lista-conteudo-item {
  cursor: pointer;
  background-color: #5c6a7e;
  padding-bottom: 1px;
}

.hoveroff {
  cursor: initial;
}

.lista-conteudo-item.active .title-conteudo {
  color: #1dad69;
}

.lista-conteudo-item:hover {
  opacity: 0.9;
}

// Fim Anotações

/* Track */
::-webkit-scrollbar {
  width: auto;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #24ce7d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #24ce7d;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .root-fixed {
    position: initial;
    overflow-y: scroll;
    overflow-x: initial;
    height: 66vh;
    width: 100%;
  }
  .divModule-opcoes p,
  .divModule-playlist p .divAula-opcoes p,
  .div-text-list p,
  .div-text-list .title-conteudo,
  .div-text-list span,
  p.divAula-opcoes,
  .divTwo-parts span {
    font-size: 12px;
  }
  .options-likes > * {
    margin-left: 3%;

    &:first-child {
      margin-left: 0px;
    }
  }
}

.conteudo-parts {
  margin: 0 0 10px 5px;
}

.popup {
  position: absolute;
  top: -10px;
  background-color: #5c6a7e;
  color: #fff;
  padding: 10px;
  left: 5px;
}

.popup.naoGostei {
  left: 30px;
}

.popup.hide-popup {
  display: none;
}

.popup.show-popup {
  display: block;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.modal-padrao {
  min-width: 450px;
  min-height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-padrao audio {
  margin: 0 auto;
}

.modal-alterar-player {
  .modal-title {
    margin-top: 20%;
    line-height: 26px;
  }
}

.modal-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;

  > a {
    text-decoration: underline;
  }
}

.bottom-modal {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.btn-cancel-modal {
  position: relative;
  bottom: 0px;
  background-color: #979797;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
}

.loading-playlist {
  width: 20px !important;
  height: 20px !important;
  margin-right: 22px;
}

.loading-margin {
  margin-top: 20px;
}

.div-pai-doubleIcon-list .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  margin-right: 15px;
}

@media (max-width: 768px) {
  .popup,
  .popup.show-popup {
    display: none;
  }
  .modal-padrao {
    width: 100%;
    min-width: 100%;
  }
}

.anotacoes .MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}

.anotacoes .MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

.anotacoes .MuiAccordion-root {
  background-color: #283241;
}

.anotacoes .MuiAccordion-root .MuiAccordionSummary-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 10px 15px 5px;
}

.anotacoes .MuiAccordion-root:before {
  height: 1px;
  background-color: #232933;
}

.anotacoes .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px;
}

.anotacoes .MuiIconButton-root {
  padding: 0px;
  color: #fff;
}

.anotacoes .MuiIconButton-root svg {
  width: 35px;
  height: 35px;
  color: #fff;
}

.anotacoes .MuiAccordionSummary-root {
  padding: 0px 0px 0px 15px;
}

.anotacoes {
  .anotacao {
    .anotacao-header {
      cursor: pointer;
      padding: 0px 15px;
      min-height: 69px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        padding: 10px 10px 15px 5px;
      }
    }

    .custom-accordion {
      display: flex;
      height: 500px;
      background-color: #eaeaea;
      padding: 5px;

      .container-annotations {
        overflow-y: hidden;
        background-color: #eaeaea;
        height: 100%;
      }
    }
  }
}

.center-loading-frame {
  position: absolute;
  left: 37%;
  top: 42%;
}

@media (max-width: 1366px) {
  .center-loading-frame {
    left: 34%;
  }
}

@media (max-width: 768px) {
  .center-loading-frame {
    left: 45%;
    top: 100px;
  }
}

/* atividades*/
.atividade-container {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.atividade-container p.atividade-chamada {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
}

.atividade-container .btn-default-yellow {
  width: 300px;
}

@media (max-width: 768px) {
  .atividade-container {
    min-height: 250px;
  }
  .atividade-container p.atividade-chamada {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.text-center {
  text-align: center;
}

.mt-5 {
  margin-top: 5em;
}

.pull-right {
  display: flex;
  justify-content: flex-end;

  .salvando {
    color: #666;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 500;
    font-size: 12px;
  }
}

.disponivel-em {
  color: #FCD64B;
  font-size: 12px;
  margin-left: 5px;
  font-weight: 400;
  margin-top: 2px;
}
